
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block3',
  mixins: [cmsBlock],

  data () {
    return {
      active: 0,
      fallbackContent: {
        activeIndex: 0,
        title: 'Fixed benefits & privileges',
        subtitle: 'only for MediClub Members',
        btn: {
          text: 'Unlock benefits',
          to: '/',
        },

        allBtnTxt: 'All benefits',
        categories: [
          {
            key: 'Treatments',
            blocks: [
              {
                title: 'Spa for glasses',
                img: '/mc/v3/block3/fixedBenefit1.png',
                label: 'label',
                text: 'Free SPA for glasses We will take care of them comprehensively. Free SPA for glasses We will take care of them comprehensively.',
                footer: 'Discount 10% with MediClub',
                to: '/opcjonalny/url',
              },
              {
                title: 'nju',
                img: '/mc/v3/block3/fixedBenefit2.png',
                footer: 'Discount 10% with MediClub',
              },
            ],
          },
          {
            key: 'Maternity',
            blocks: [
              {
                title: 'Spa for glasses 2 logner text test',
                img: '/mc/v3/block3/fixedBenefit3.png',
                label: 'RABAT',
                text: 'Free SPA for glasses We will take care of them comprehensively.',
                footer: 'Discount 10% with MediClub',
              },
            ],
          },
          {
            key: 'Kids',
            blocks: [
              {
                title: 'Spa for glasses 3',
                img: '/mc/v3/block3/fixedBenefit4.png',
                label: '-99%',
                text: 'Free SPA for glasses We will take care of them comprehensively.',
                footer: 'Discount 10% with MediClub',
                to: '/opcjonalny/url',
              },
              {
                title: 'Spa for glasses 2',
                img: '/mc/v3/block3/fixedBenefit1.png',
                label: 'RABAT',
                text: 'Free SPA for glasses We will take care of them comprehensively.',
                footer: 'Discount 10% with MediClub',
              },
            ],
          },
        ],
      },
    }
  },

  computed: {
    benefitMenu () {
      return [this.blockContent.allBtnTxt, ...this.blockContent.categories.map(({ key }) => key)].filter(Boolean)
    },
  },

  watch: {
    active () {
      this.$nextTick(() => {
        this.$refs.carousel?.scrollToStart?.('instant')
      })
    },
  },

  created () {
    this.active = this.blockContent.activeIndex || 0
  },
}
