
import baseLink from '~/mixins/baseLink'

export default {
  name: 'CmsMediclub3BenefitItem',
  mixins: [baseLink],

  props: {
    item: {
      type: Object,
      required: true,
    },

    grayFooter: {
      type: Boolean,
      default: false,
    },
  },
}
