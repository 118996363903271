
export default {
  name: 'CmsMediclub3BenefitMenu',

  props: {
    value: { type: Number, required: true },
    items: { type: Array, required: true },
    first: { type: Number, default: 0 },
  },

  methods: {
    getItemOrder (index) {
      if (!this.first) {
        return 0
      }

      if (index === 0) {
        return 2
      }

      if (index === this.first) {
        return 0
      }

      return 1
    },
  },
}
