
export default {
  name: 'PriceLowest',

  props: {
    price: {
      type: Object,
      default: null,
    },
  },

  methods: {
    formatPrice () {
      const currency = ['USD', 'PLN', 'RON', 'EUR'].includes(this.price?.currency) ? this.price?.currency : 'PLN'

      return new Intl
        .NumberFormat(this.$i18n.locale, { style: 'currency', currency })
        .format(this.price?.value)
    },
  },
}
